<template>
  <div>
    <Breadcrumbs
      :items="breadcrumbs"
      page_title="requesteduserreport"
      :item_no="item_No ? item_No : 0"
    />
    <v-container fluid>
      <!-- New Ui -->
      <v-card class="rounded-lg">
        <v-container fluid>
          <!-- <v-toolbar class="elevation-0"> -->
          <v-row no-gutters>
            <v-col cols="12" xs="12" md="7">
              <div class="d-flex flex-row">
                <v-tabs
                  grow
                  class="tab-mobileres"
                  color="secondary"
                  direction="vertical"
                  show-arrows
                >
                  <v-tabs-slider></v-tabs-slider>
                  <v-tab
                    tag
                    class="text-capitalize tab-style"
                    style="font-size: 20px; width: 130px"
                    @click="
                      $store.commit('changeTab', 'tab-1'),
                        (course = []),
                        (values = []),
                        (dept = []),
                        (names = [])
                    "
                  >
                    <v-icon style="font-size: 30px" class="mr-1"
                      >mdi-file-document</v-icon
                    >
                    {{ $t("article") }}
                  </v-tab>

                  <v-tab
                    class="text-capitalize tab-style"
                    style="font-size: 20px; width: 150px"
                    @click="
                      $store.commit('changeTab', 'tab-2'),
                        (course = []),
                        (values = []),
                        (dept = []),
                        (names = [])
                    "
                  >
                    <v-icon style="font-size: 30px" class="mr-1"
                      >mdi-volume-high</v-icon
                    >
                    {{ $t("sound") }}
                  </v-tab>

                  <v-tab
                    class="text-capitalize tab-style"
                    style="font-size: 20px; width: 130px"
                    @click="
                      $store.commit('changeTab', 'tab-3'),
                        (course = []),
                        (values = []),
                        (dept = []),
                        (names = [])
                    "
                  >
                    <v-icon style="font-size: 30px" class="mr-1"
                      >mdi-youtube-tv</v-icon
                    >
                    {{ $t("video") }}
                  </v-tab>

                  <v-tab
                    class="text-capitalize tab-style"
                    style="font-size: 20px"
                    @click="
                      $store.commit('changeTab', 'tab-4'),
                        (course = []),
                        (values = []),
                        (dept = []),
                        (names = [])
                    "
                  >
                    <v-icon style="font-size: 30px" class="mr-1"
                      >mdi-notebook</v-icon
                    >
                    {{ $t("lessonlearn") }}
                  </v-tab>
                </v-tabs>
              </div>
            </v-col>
            <v-spacer></v-spacer>
            <v-col
              cols="12"
              xs="12"
              md="5"
              lg="5"
              class="d-flex justify-end align-center"
              :class="$vuetify.breakpoint.smAndDown ? 'mt-2' : 'mt-0'"
            >
              <v-btn
                class="text-capitalize px-2"
                style="
                  color: #424242;
                  font-size: 16px;
                  border: 1px solid #c0c0c0;
                  box-shadow: unset !important;
                  border-radius: 8px;
                  background: #ffffff 0% 0% no-repeat padding-box;
                "
                @click="showFilterTools = !showFilterTools"
              >
                <v-icon class="mr-2" :small="true">mdi-tune-variant</v-icon>
                {{ $t("filter") }}
              </v-btn>

              <v-btn
                outlined
                @click="exportExcel"
                style="
                  color: #a6cc39;
                  font-size: 16px;
                  border: 1px solid #c0c0c0;
                  box-shadow: unset !important;
                  border-radius: 8px;
                  background: #ffffff 0% 0% no-repeat padding-box;
                "
                class="ml-3 text-capitalize px-2"
              >
                <v-icon class="mr-2 mb-1">mdi-export-variant</v-icon>
                {{ $t("exportexcel") }}
              </v-btn>
            </v-col>
          </v-row>
          <!-- </v-toolbar> -->
          <v-divider
            :class="$vuetify.breakpoint.smAndDown ? 'mt-2' : 'mt-0'"
          ></v-divider>

          <v-row v-if="showFilterTools" class="px-2 mt-1">
            <v-col
              cols="12"
              xs="12"
              sm="6"
              md="3"
              lg="3"
              class="pre-fix"
              v-if="this.$store.getters.tabs != 'tab-4'"
            >
              <label
                for="category"
                style="
                  font-size: 16px;
                  color: #47484b;
                  font-weight: bold;
                  margin-top: 5px;
                "
                >{{ $t("category") }}
              </label>
              <v-select
                v-model="course"
                :items="Categoryitems"
                hide-details
                item-text="topicName"
                hide-no-data
                item-value="id"
                solo
                @change="(event) => SelectCategory(event)"
                @blur="clearSearchWord(searchtype)"
                dense
                placeholder="-- Please select --"
                clearable
                multiple
              >
                <template v-slot:prepend-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-text-field
                        v-model="searchcategory"
                        :placeholder="$t('search')"
                        @input="searchInLists(), (searchtype = 'category')"
                        autocomplete="off"
                        hide-details
                        class="searchinlist"
                        append-icon="mdi-magnify"
                      ></v-text-field>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item ripple @click="getSelectAll">
                    <v-list-item-action>
                      <v-icon :color="course.length > 0 ? '#a6cc39' : ''">{{
                        icon
                      }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>Select All</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0">
                    <span>{{ item.topicName }}</span>
                  </v-chip>
                  <span v-if="index === 1" class="grey--text text-caption"
                    >(+{{ course.length - 1 }} others)</span
                  >
                </template>
              </v-select>
            </v-col>            
            <v-col cols="12" xs="12" sm="6" md="3" lg="3" class="pre-fix">
              <label
                for="title"
                style="
                  font-size: 16px;
                  color: #47484b;
                  font-weight: bold;
                  margin-top: 5px;
                "
                >{{ $t("title") }}
              </label>
              <v-select
                v-model="values"
                :items="Titleitems"
                hide-no-data
                placeholder="-- Please select --"
                hide-details
                solodense
                item-text="title"
                item-value="id"
                dense
                solo
                @change="(event) => SelectTitle(event)"
                @blur="clearSearchWord(searchtype)"
                clearable
                multiple
              >
                <template v-slot:prepend-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-text-field
                        v-model="searchtitle"
                        :placeholder="$t('search')"
                        @input="searchInLists(), (searchtype = 'title')"
                        autocomplete="off"
                        hide-details
                        class="searchinlist"
                        append-icon="mdi-magnify"
                      ></v-text-field>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item ripple @click="SelectAllTitle">
                    <v-list-item-action>
                      <v-icon :color="values.length > 0 ? '#a6cc39' : ''">{{
                        Titleicon
                      }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>Select All</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0">
                    <span>{{ item.title }}</span>
                  </v-chip>
                  <span v-if="index === 1" class="grey--text text-caption"
                    >(+{{ values.length - 1 }} others)</span
                  >
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="3" lg="3" v-if="this.$store.getters.tabs === 'tab-4'"></v-col>
            <v-col
              cols="12"
              xs="12"
              md="6"
              lg="6"
              class="d-flex justify-end align-end"
            >
              <v-text-field
                outlined
                dense
                clearable
                style="border-radius: 8px"
                prepend-inner-icon="mdi-magnify"
                :placeholder="$t('search')"
                :style="
                  $vuetify.breakpoint.smAndDown
                    ? 'max-width: unset'
                    : 'max-width: 250px'
                "
                v-model="search"
                hide-details
              >
              </v-text-field>
            </v-col>
          </v-row>

          <v-row v-if="showFilterTools" class="px-2">
            <v-col cols="12" xs="12" sm="6" md="3"  xl="3" class="pre-fix">
              <label
                for="group"
                style="
                  font-size: 16px;
                  color: #47484b;
                  font-weight: bold;
                  margin-top: 5px;
                "
              >
                {{ $t("group") }}
              </label>
              <v-select
                v-model="dept"
                :items="Departmentitems"
                hide-no-data
                hide-details
                item-text="groupName"
                placeholder="-- Please select --"
                item-value="groupID"
                solo
                @change="(event) => SelectDept(event)"
                @blur="clearSearchWord(searchtype)"
                dense
                clearable
                multiple
              >
                <template v-slot:prepend-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-text-field
                        v-model="searchgroup"
                        :placeholder="$t('search')"
                        @input="searchInLists(), (searchtype = 'group')"
                        autocomplete="off"
                        hide-details
                        class="searchinlist"
                        append-icon="mdi-magnify"
                      ></v-text-field>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item ripple @click="getAllDept">
                    <v-list-item-action>
                      <v-icon :color="dept.length > 0 ? '#a6cc39' : ''">{{
                        Depticon
                      }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>Select All</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0">
                    <span>{{ item.groupName }}</span>
                  </v-chip>
                  <span v-if="index === 1" class="grey--text text-caption"
                    >(+{{ dept.length - 1 }} others)</span
                  >
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="3" xl="3" class="pre-fix">
              <label
                for="surname"
                style="
                  font-size: 16px;
                  color: #47484b;
                  font-weight: bold;
                  margin-top: 5px;
                "
                >{{ $t("surname") }}:</label
              >
              <v-select
                v-model="names"
                :items="Surnameitems"
                hide-no-data
                hide-details
                item-text="fullName"
                item-value="userID"
                solo
                @change="(event) => SelectName(event)"
                dense
                placeholder="-- Please select --"
                @blur="clearSearchWord(searchtype)"
                clearable
                multiple
              >
                <template v-slot:prepend-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-text-field
                        v-model="searchname"
                        :placeholder="$t('search')"
                        @input="searchInLists(), (searchtype = 'surname')"
                        autocomplete="off"
                        hide-details
                        class="searchinlist"
                        append-icon="mdi-magnify"
                      ></v-text-field>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item ripple @click="getAllName">
                    <v-list-item-action>
                      <v-icon :color="names.length > 0 ? '#a6cc39' : ''">{{
                        Nameicon
                      }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>Select All</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0">
                    <span>{{ item.fullName }}</span>
                  </v-chip>
                  <span v-if="index === 1" class="grey--text text-caption"
                    >(+{{ names.length - 1 }} others)</span
                  >
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="6" xl="6" class="d-flex align-end">
              <v-btn
                text
                class="text-capitalize px-2"
                height="40"
                style="
                  border: 1px solid #c0c0c0;
                  border-radius: 8px;
                  color: #424242;
                  font-size: 16px;
                "
                @click="(course = []), (values = []), (dept = []), (names = [])"
                >{{ $t("clearall") }}</v-btn
              >
              <v-btn
                min-width="100"
                height="40"
                class="text-capitalize btn_hover_effect ml-3 px-2"
                style="
                  color: #ffff;
                  border: 1px solid #a6cc39;
                  border-radius: 8px;
                  font-size: 16px;
                "
                color="#A6CC39"
                @click="Search()"
                >{{ $t("search") }}</v-btn
              >
            </v-col>
          </v-row>
          <v-tabs-items v-model="tabs" class="mt-1">
            <v-tab-item value="tab-1">
              <v-data-table
                :loading="loading"
                loading-text="Loading... Please wait"
                :headers="headers"
                :items="ArticleList"
                :search="search"
                hide-default-footer
                @page-count="pageCount = $event"
                :page.sync="page"
                :items-per-page="itemsPerPage"
                class="pt-6 view-table"
              >
                <template v-slot:item.actions="{ item }">
                  <v-icon color="#ECC407" @click="Details(item)"
                    >mdi-alert-circle</v-icon
                  >
                </template>
              </v-data-table>
              <v-row justify="space-between">
                <v-col cols="auto" class="mr-auto">
                  <div class="d-flex text-left">
                    <v-pagination
                      circle
                      v-model="page"
                      class="pagination"
                      :length="pageCount"
                    ></v-pagination>
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  lg="2"
                  md="3"
                  sm="4"
                  class="d-flex justify-end"
                >
                  <p class="mt-3 pr-4">{{ $t("Show") }}</p>
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        width="70"
                        v-bind="attrs"
                        v-on="on"
                        style="border: 1px solid #c0c0c0; opacity: 1"
                        class="text-capitalize mt-1"
                        outlined
                      >
                        {{ itemsPerPage }}
                        <v-icon>mdi-chevron-down</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="(item, index) in paginationCount"
                        :key="index"
                        @click="itemsPerPage = item.title"
                      >
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item value="tab-2">
              <v-data-table
                :loading="loading"
                loading-text="Loading... Please wait"
                :headers="soundHeaders"
                :items="ArticleList"
                :search="search"
                hide-default-footer
                @page-count="pageCount = $event"
                :page.sync="page"
                :items-per-page="itemsPerPage"
                class="pt-6 view-table"
              >
                <template v-slot:item.actions="{ item }">
                  <v-icon color="#ECC407" @click="MediaDetails(item)"
                    >mdi-alert-circle</v-icon
                  >
                </template>
              </v-data-table>
              <v-row justify="space-between">
                <v-col cols="auto" class="mr-auto">
                  <div class="d-flex text-left">
                    <v-pagination
                      circle
                      v-model="page"
                      class="pagination"
                      :length="pageCount"
                    ></v-pagination>
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  lg="2"
                  md="3"
                  sm="4"
                  class="d-flex justify-end"
                >
                  <p class="mt-3 pr-4">{{ $t("Show") }}</p>
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        width="70"
                        v-bind="attrs"
                        v-on="on"
                        style="border: 1px solid #c0c0c0; opacity: 1"
                        class="text-capitalize mt-1"
                        outlined
                      >
                        {{ itemsPerPage }}
                        <v-icon>mdi-chevron-down</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="(item, index) in paginationCount"
                        :key="index"
                        @click="itemsPerPage = item.title"
                      >
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item value="tab-3">
              <v-data-table
                :loading="loading"
                loading-text="Loading... Please wait"
                :headers="soundHeaders"
                :items="ArticleList"
                :search="search"
                hide-default-footer
                @page-count="pageCount = $event"
                :page.sync="page"
                :items-per-page="itemsPerPage"
                class="pt-6 view-table"
              >
                <template v-slot:item.actions="{ item }">
                  <v-icon color="#ECC407" @click="MediaDetails(item)"
                    >mdi-alert-circle</v-icon
                  >
                </template>
              </v-data-table>
              <v-row justify="space-between">
                <v-col cols="auto" class="mr-auto">
                  <div class="d-flex text-left">
                    <v-pagination
                      circle
                      v-model="page"
                      class="pagination"
                      :length="pageCount"
                    ></v-pagination>
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  lg="2"
                  md="3"
                  sm="4"
                  class="d-flex justify-end"
                >
                  <p class="mt-3 pr-4">{{ $t("Show") }}</p>
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        width="70"
                        v-bind="attrs"
                        v-on="on"
                        style="border: 1px solid #c0c0c0; opacity: 1"
                        class="text-capitalize mt-1"
                        outlined
                      >
                        {{ itemsPerPage }}
                        <v-icon>mdi-chevron-down</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="(item, index) in paginationCount"
                        :key="index"
                        @click="itemsPerPage = item.title"
                      >
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item value="tab-4">
              <v-data-table
                :loading="loading"
                loading-text="Loading... Please wait"
                :headers="headersLL"
                :items="ArticleList"
                :search="search"
                hide-default-footer
                @page-count="pageCount = $event"
                :page.sync="page"
                :items-per-page="itemsPerPage"
                class="pt-6 view-table"
              >
                <template v-slot:item.actions="{ item }">
                  <v-icon color="#ECC407" @click="Details(item)"
                    >mdi-alert-circle</v-icon
                  >
                </template>
              </v-data-table>
              <v-row justify="space-between">
                <v-col cols="auto" class="mr-auto">
                  <div class="d-flex text-left">
                    <v-pagination
                      circle
                      v-model="page"
                      class="pagination"
                      :length="pageCount"
                    ></v-pagination>
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  lg="2"
                  md="3"
                  sm="4"
                  class="d-flex justify-end"
                >
                  <p class="mt-3 pr-4">{{ $t("Show") }}</p>
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        width="70"
                        v-bind="attrs"
                        v-on="on"
                        style="border: 1px solid #c0c0c0; opacity: 1"
                        class="text-capitalize mt-1"
                        outlined
                      >
                        {{ itemsPerPage }}
                        <v-icon>mdi-chevron-down</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="(item, index) in paginationCount"
                        :key="index"
                        @click="itemsPerPage = item.title"
                      >
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </v-container>
      </v-card>

      <v-dialog
        v-model="detaildialog"
        width="1000"
        persistent
        :scrollable="true"
      >
        <v-card>
          <v-card-title style="font-size: 20px">
            <p class="dialog-p">
              <v-icon class="mb-1">mdi-information-outline</v-icon
              >{{ $t("detail") }}
            </p>
            <v-spacer></v-spacer>
            <v-btn
              outlined
              @click="exportDetailExcel()"
              style="
                color: #a6cc39;
                font-size: 16px;
                border: 1px solid #c0c0c0;
                box-shadow: unset !important;
                border-radius: 8px;
                background: #ffffff 0% 0% no-repeat padding-box;
              "
              class="ml-3 text-capitalize"
            >
              <v-icon class="mr-2 mb-1">mdi-export-variant</v-icon>
              {{ $t("exportexcel") }}
            </v-btn>
            <v-btn
              icon
              @click="detaildialog = false"
              class="ml-3"
              color="#424242"
              style="
                border-radius: 8px !important;
                border: 1px solid rgba(66, 66, 66, 0.05);
              "
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="mt-5">
            <v-row>
              <v-col cols="6" md="6" lg="6">
                <v-row
                  class="mt-1 ml-0"
                  v-if="this.$store.getters.tabs != 'tab-4'"
                >
                  <v-col>
                    <label
                      for="category"
                      style="
                        font-size: 16px;
                        color: #47484b;
                        font-weight: bold;
                        margin-top: 5px;
                      "
                      >{{ $t("category") }}:</label
                    >
                  </v-col>
                  <v-col>
                    <label
                      for="category"
                      style="font-size: 16px; color: #47484b; margin-top: 5px"
                      >{{ detail.category }}</label
                    >
                  </v-col>
                </v-row>
                <v-row class="mt-1 ml-0">
                  <v-col>
                    <label
                      for="title"
                      style="
                        font-size: 16px;
                        color: #47484b;
                        font-weight: bold;
                        margin-top: 5px;
                      "
                      >{{ $t("title") }}:</label
                    >
                  </v-col>
                  <v-col>
                    <label
                      for="title"
                      style="font-size: 16px; color: #47484b; margin-top: 5px"
                      >{{ detail.title }}</label
                    >
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                cols="6"
                md="6"
                lg="6"
                style="padding-left: 10px"
                class="ml-0 px"
              >
                <v-spacer></v-spacer>
                <v-card
                  elevation="0"
                  style="border-radius: 16px; margin-left: 10px"
                  class="mt-1 mx-auto"
                  max-width="320"
                >
                  <apexchart
                    type="donut"
                    :key="chartkey"
                    :options="chartOptionsarticle"
                    :series="articleseries"
                    :width="chartWidth"
                    :height="chartHeight"
                  />
                </v-card>
              </v-col>
            </v-row>
            <v-col cols="12">
              <v-row>
                <v-col cols="2" lg="3" md="1" sm="1">
                  <label
                    for="status"
                    style="
                      font-size: 16px;
                      color: #47484b;
                      font-weight: bold;
                      margin-top: 5px;
                    "
                    >{{ $t("status") }}:</label
                  >
                </v-col>
                <v-col cols="4" lg="4" md="6" sm="6">
                  <div>
                    <v-select
                      :items="StatusItems"
                      hide-no-data
                      v-model="statusdata"
                      hide-details
                      item-text="text"
                      item-value="text"
                      solo
                      dense
                      multiple
                      clearable
                      placeholder="-- Please select --"
                    >
                      <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index === 0">
                          <span>{{ item.text }}</span>
                        </v-chip>
                        <span v-if="index === 1" class="grey--text text-caption"
                          >(+{{ statusdata.length - 1 }} others)</span
                        >
                      </template>
                    </v-select>
                  </div>
                </v-col>
                <v-col>
                  <v-btn
                    width="120"
                    height="40"
                    class="text-capitalize btn_hover_effect"
                    style="
                      color: #ffff;
                      border: 1px solid #a6cc39;
                      border-radius: 8px;
                      font-size: 16px;
                    "
                    color="#A6CC39"
                    @click="Searchstatus()"
                    >{{ $t("search") }}</v-btn
                  >
                </v-col>
              </v-row>
            </v-col>

            <v-data-table
              :loading="detailloading"
              loading-text="Loading... Please wait"
              :headers="detailsheader"
              :items="DetailsList"
              class="mt-7 ml-2 mr-2 view-table"
              hide-default-footer
              @page-count="detailpageCount = $event"
              :page.sync="detailpage"
            >
              <template v-slot:item.status="{ item }">
                <v-chip
                  :class="
                    item.status == 'Finished'
                      ? 'finished'
                      : item.status == 'Not Finished'
                      ? 'notfinished'
                      : item.status == 'After expired date'
                      ? 'afterexpired'
                      : item.status == 'Before expired date'
                      ? 'before'
                      : 'notstarted'
                  "
                  >{{ item.status }}</v-chip
                >
              </template>
            </v-data-table>
            <v-row justify="space-between">
              <div class="ma-5 d-flex">
                <v-pagination
                  circle
                  class="pagination"
                  v-model="detailpage"
                  :length="detailpageCount"
                ></v-pagination>
              </div>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="mediaDialog"
        width="1000"
        persistent
        :scrollable="true"
      >
        <v-card>
          <v-card-title style="font-size: 20px">
            <p class="dialog-p">
              <v-icon class="mb-1">mdi-information-outline</v-icon
              >{{ $t("detail") }}
            </p>
            <v-spacer></v-spacer>
            <v-btn
              outlined
              @click="exportDetailExcel()"
              style="
                color: #a6cc39;
                font-size: 16px;
                border: 1px solid #c0c0c0;
                box-shadow: unset !important;
                border-radius: 8px;
                background: #ffffff 0% 0% no-repeat padding-box;
              "
              class="ml-3 text-capitalize"
            >
              <v-icon class="mr-2 mb-1">mdi-export-variant</v-icon>
              {{ $t("exportexcel") }}
            </v-btn>
            <v-btn
              icon
              @click="mediaDialog = false"
              class="ml-3"
              color="#424242"
              style="
                border-radius: 8px !important;
                border: 1px solid rgba(66, 66, 66, 0.05);
              "
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider></v-divider>

          <v-card-text class="mt-5">
            <v-row>
              <v-col cols="6" class="pt-0 pl-0">
                <v-row class="mt-1 ml-2">
                  <v-col
                    ><label
                      for="category"
                      style="
                        font-size: 16px;
                        color: #47484b;
                        font-weight: bold;
                        margin-top: 5px;
                      "
                      >{{ $t("category") }}:</label
                    >
                  </v-col>
                  <v-col>
                    <label
                      for="category"
                      style="font-size: 16px; color: #47484b; margin-top: 5px"
                      >{{ detail.category }}</label
                    >
                  </v-col>
                </v-row>
                <v-row class="mt-1 ml-2">
                  <v-col>
                    <label
                      for="title"
                      style="
                        font-size: 16px;
                        color: #47484b;
                        font-weight: bold;
                        margin-top: 5px;
                      "
                      >{{ $t("title") }}:</label
                    >
                  </v-col>
                  <v-col>
                    <label
                      for="title"
                      style="
                        font-size: 16px;
                        color: #47484b;
                        margin-top: 5px;
                        margin-left: 0px;
                      "
                      >{{ detail.title }}</label
                    >
                  </v-col>
                </v-row>
                <v-row class="mt-1 ml-2">
                  <v-col>
                    <label
                      for="filenameL"
                      style="
                        font-size: 16px;
                        color: #47484b;
                        font-weight: bold;
                        margin-top: 5px;
                      "
                      >{{ $t("filenameL") }}:</label
                    >
                  </v-col>
                  <v-col>
                    <label
                      for="file"
                      style="font-size: 16px; color: #47484b; margin-top: 5px"
                      >{{ detail.file }}</label
                    >
                  </v-col>
                </v-row>
                <v-row class="mt-1 ml-2">
                  <v-col>
                    <label
                      for="Time"
                      style="
                        font-size: 16px;
                        color: #47484b;
                        font-weight: bold;
                        margin-top: 5px;
                      "
                      >{{ $t("Time") }}:</label
                    >
                  </v-col>
                  <v-col>
                    <label
                      for="time"
                      style="font-size: 16px; color: #47484b; margin-top: 5px"
                      >{{ detail.time }}</label
                    >
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="6" class="pt-0 pl-10">
                <v-spacer></v-spacer>
                <div class="pl-10">
                  <v-card
                    elevation="0"
                    style="border-radius: 16px"
                    class="mt-1 ml-3 mx-auto"
                    max-width="370"
                  >
                    <apexchart
                      type="donut"
                      :key="chartkey"
                      :options="chartOptionscourse"
                      :series="videoseries"
                    />
                  </v-card>
                </div>
              </v-col>
            </v-row>
            <v-col cols="12">
              <v-row>
                <v-col cols="2" lg="3" md="1" sm="1">
                  <label
                    for="status"
                    style="
                      font-size: 16px;
                      color: #47484b;
                      font-weight: bold;
                      margin-top: 5px;
                    "
                    >{{ $t("status") }}:</label
                  >
                </v-col>
                <v-col cols="4" lg="4" md="6" sm="6">
                  <div>
                    <v-select
                      :items="StatusSoundVideoItems"
                      hide-no-data
                      v-model="statusdata"
                      hide-details
                      item-text="text"
                      item-value="text"
                      solo
                      dense
                      multiple
                      clearable
                      placeholder="-- Please select --"
                    >
                      <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index === 0">
                          <span>{{ item.text }}</span>
                        </v-chip>
                        <span v-if="index === 1" class="grey--text text-caption"
                          >(+{{ statusdata.length - 1 }} others)</span
                        >
                      </template>
                    </v-select>
                  </div>
                </v-col>
                <v-col>
                  <v-btn
                    width="120"
                    height="40"
                    class="text-capitalize btn_hover_effect"
                    style="
                      color: #ffff;
                      border: 1px solid #a6cc39;
                      border-radius: 8px;
                      font-size: 16px;
                    "
                    color="#A6CC39"
                    @click="Searchstatus()"
                    >{{ $t("search") }}</v-btn
                  >
                </v-col>
              </v-row>
            </v-col>
            <v-row class="mt-1 ml-2"> </v-row>
            <v-data-table
              :loading="loading"
              loading-text="Loading... Please wait"
              :headers="mediadetailsheader"
              :items="DetailsList"
              class="mt-7 ml-2 mr-2 view-table"
              @page-count="detailpageCount = $event"
              :page.sync="detailpage"
              hide-default-footer
            >
              <template v-slot:item.status="{ item }">
                <v-chip
                  :class="
                    item.status == 'Finished'
                      ? 'finished'
                      : item.status == 'Not Finished'
                      ? 'notfinished'
                      : item.status == 'After expired date'
                      ? 'afterexpired'
                      : item.status == 'Before expired date'
                      ? 'before'
                      : 'notstarted'
                  "
                  >{{ item.status }}</v-chip
                >
              </template>
            </v-data-table>
            <v-row justify="start">
              <div class="ma-5 d-flex">
                <v-pagination
                  circle
                  class="pagination"
                  v-model="detailpage"
                  :length="detailpageCount"
                ></v-pagination>
              </div>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import exceljs from "exceljs";
import { saveAs } from "file-saver";
import VueApexCharts from "vue-apexcharts";
export default {
  components: {
    apexchart: VueApexCharts,
  },
  data: () => ({
    item_No: 0,
    showFilterTools: false,
    searchtype: null,
    groupCopy: [],
    searchgroup: null,
    categoryCopy: [],
    titleCopy: [],
    searchcategory: null,
    searchtitle: null,
    searchname: null,
    surnameCopy: [],
    breadcrumbs: [
      // {
      //   image: "Report.png"
      // },
      {
        sidebar_tilte: "report", //home
      },
      {
        text: "requesteduserreport",
      },
    ],
    dateoffset: new Date().getTimezoneOffset(),
    detailloading: false,
    Categoryitems: [],
    Titleitems: [],
    Departmentitems: [],
    Surnameitems: [],
    RequestedList: [],
    course: [],
    values: [],
    dept: [],
    names: [],
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    detaildialog: false,
    mediaDialog: false,
    detailpage: 1,
    detailpageCount: 0,
    search: null,
    loading: false,
    tabValue: "tab-1",
    tempTitle: [],
    tempName: [],
    detail: {
      category: "",
      title: "",
      file: "",
      time: "15:02m",
    },
    ArticleList: [],
    LessonLearnList: [],
    DetailsList: [],
    paginationCount: [{ title: 10 }, { title: 20 }, { title: 30 }],
    statusdata: [],
    videoseries: [],
    articleseries: [],
    chartOptionscourse: {
      chart: {
        type: "donut",
      },
      labels: [],
      fill: {
        colors: ["#FF3D3D", "#ffc000", "#d4e665", "#A6CC38"],
      },
      colors: ["#FF3D3D", "#ffc000", "#d4e665", "#A6CC38"],
      grid: {
        show: true,
      },
      dataLabels: {
        enabled: true,
      },
      legend: {
        show: true,
        position: "right", // Set label position to the top
        offsetY: -5,
      },
      stroke: {
        show: false,
      },
      plotOptions: {
        pie: {
          // innerSize: 450,
          donut: {
            size: "50%",
          },
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
      tooltip: {
        y: {
          formatter: function () {
            return "";
          },
          title: {
            formatter: function (seriesName, val) {
              return (
                "<div style='text-align: center;'>" +
                seriesName +
                "<br>" +
                "<span class='tooltip-value'>" +
                val.series[val.seriesIndex] +
                "%</span></div>"
              );
            },
          },
        },
        fixed: {
          enabled: false,
          position: "topRight",
          offsetX: 0,
          offsetY: 0,
        },
        style: {
          textAlign: "center",
        },
      },
      // tooltip: {
      //   y: {
      //     formatter: function () {
      //       return "";
      //     },
      //     title: {
      //       formatter: function (seriesName, val) {
      //         return seriesName + "<br>" + val.series[val.seriesIndex] + "%";
      //       },
      //     },
      //   },
      //   fixed: {
      //     enabled: true,
      //     position: "topRight",
      //     offsetX: 0,
      //     offsetY: 0,
      //   },
      // },
    },
    chartkey: 0,
    chartWidth: "100%",
    chartHeight: "300px",
    chartOptionsarticle: {
      chart: {
        type: "donut",
      },
      labels: [],
      fill: {
        colors: ["#FF3D3D", "#d4e665", "#A6CC38"],
      },
      colors: ["#FF3D3D", "#d4e665", "#A6CC38"],
      grid: {
        show: true,
      },
      dataLabels: {
        enabled: true,
      },
      legend: {
        show: true,
        position: "right", // Set label position to the top
        offsetY: -5,
      },
      stroke: {
        show: false,
      },
      plotOptions: {
        pie: {
          // innerSize: 450,
          donut: {
            size: "50%",
          },
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
      tooltip: {
        onDatasetHover: {
          highlightDataSeries: false,
        },
        fixed: {
          enabled: false,
          position: "bottomtop",
          // offsetX: 0,
          offsetY: 0,
        },
        y: {
          formatter: function () {
            return "";
          },
          title: {
            formatter: function (seriesName, val) {
              return (
                "<div style='text-align: center;'>" +
                seriesName +
                "<br>" +
                "<span class='tooltip-value'>" +
                val.series[val.seriesIndex] +
                "%</span></div>"
              );
            },
          },
        },
      },
    },
  }),
  computed: {
    headers() {
      return [
        {
          text: this.$t("category"),
          value: "topicName",
          align: "left",
        },
        {
          text: this.$t("title"),
          value: "titleName",
          align: "left",
        },
        {
          text: this.$t("requesteduser"),
          value: "requestedUser",
          align: "center",
        },
        {
          text: this.$t("finishviewuser"),
          value: "finishedUser",
          align: "center",
        },
        {
          text: this.$t("finishbeforeexp"),
          value: "finishBeforeExpiration",
          align: "center",
        },
        {
          text: this.$t("finishafterexp"),
          value: "finishedAfterExpiration",
          align: "center",
        },
        {
          text: this.$t("notstarted"),
          value: "notFinishedUser",
          align: "center",
        },
        {
          text: this.$t("detail"),
          value: "actions",
          align: "center",
        },
      ];
    },
    headersLL() {
      return [
        {
          text: this.$t("title"),
          value: "titleName",
          align: "left",
        },
        {
          text: this.$t("requesteduser"),
          value: "requestedUser",
          align: "center",
        },
        {
          text: this.$t("finishviewuser"),
          value: "finishedUser",
          align: "center",
        },
        {
          text: this.$t("finishbeforeexp"),
          value: "finishBeforeExpiration",
          align: "center",
        },
        {
          text: this.$t("finishafterexp"),
          value: "finishedAfterExpiration",
          align: "center",
        },
        {
          text: this.$t("notstarted"),
          value: "notFinishedUser",
          align: "center",
        },
        {
          text: this.$t("details"),
          value: "actions",
          align: "center",
        },
      ];
    },
    soundHeaders() {
      return [
        {
          text: this.$t("category"),
          value: "topicName",
          align: "left",
        },
        {
          text: this.$t("title"),
          value: "titleName",
          align: "left",
        },
        {
          text: this.$t("filenameL"),
          value: "fileName",
          align: "left",
        },
        {
          text: this.$t("requesteduser"),
          value: "requestedUser",
          align: "center",
        },
        {
          text: this.$t("finishviewuser"),
          value: "finishedUser",
          align: "center",
        },
        {
          text: this.$t("finishbeforeexp"),
          value: "finishBeforeExpiration",
          align: "center",
        },
        {
          text: this.$t("finishafterexp"),
          value: "finishedAfterExpiration",
          align: "center",
        },
        {
          text: this.$t("notfinish"),
          value: "notFinishedUser",
          align: "center",
        },
        {
          text: this.$t("notstarted"),
          value: "notStartedUser",
          align: "center",
        },
        {
          text: this.$t("detail"),
          value: "actions",
          align: "center",
        },
      ];
    },
    StatusItems() {
      return [
        {
          text: "Not started",
        },
        {
          text: "Before expired date",
        },
        {
          text: "After expired date",
        },
      ];
    },
    StatusSoundVideoItems() {
      return [
        {
          text: "Not started",
        },
        {
          text: "Not finished",
        },
        {
          text: "Before expired date",
        },
        {
          text: "After expired date",
        },
      ];
    },
    tabs: {
      get() {
        this.TabChange();
        if (this.$store.getters.tabs == "tab-2") {
          this.GetSoundTitle();
        } else if (this.$store.getters.tabs == "tab-3") {
          this.GetVideoTitle();
        } else if (this.$store.getters.tabs == "tab-4") {
          this.GetLessonLearnTitle();
        } else {
          this.GetArticleTitle();
        }
        return this.$store.getters.tabs;
      },
      set() {
        this.$store.commit("changeTab", this.tabValue);
      },
    },
    detailsheader() {
      return [
        {
          text: this.$t("surname"),
          value: "fullName",
          align: "left",
        },
        {
          text: this.$t("group"),
          value: "departmentName",
          align: "left",
        },
        {
          text: this.$t("expireddate"),
          value: "expiredDate",
          align: "left",
        },
        {
          text: this.$t("firstviewdate"),
          value: "firstViewDate",
          align: "left",
        },

        {
          text: this.$t("status"),
          value: "status",
          align: "left",
          width: "115px",
        },
      ];
    },
    mediadetailsheader() {
      return [
        {
          text: this.$t("surname"),
          value: "fullName",
          align: "left",
        },
        {
          text: this.$t("group"),
          value: "departmentName",
          align: "left",
        },
        {
          text: this.$t("expireddate"),
          value: "expiredDate",
          align: "center",
        },
        {
          text: this.$t("firstviewdate"),
          value: "firstViewDate",
          align: "left",
        },
        {
          text: this.$t("lastviewdate"),
          value: "lastViewDate",
          align: "center",
        },
        {
          text: this.$t("ViewTime"),
          value: "viewTime",
          align: "left",
        },
        {
          text: this.$t("status"),
          value: "status",
          align: "center",
        },
      ];
    },
    selectAll() {
      return this.course.length === this.Categoryitems.length;
    },
    selectAllTitle() {
      return this.values.length === this.Titleitems.length;
    },
    selectAllDept() {
      return this.dept.length === this.Departmentitems.length;
    },
    selectAllName() {
      return this.names.length === this.Surnameitems.length;
    },
    icon() {
      if (this.selectAll) return "mdi-checkbox-marked";
      return "mdi-checkbox-blank-outline";
    },
    Titleicon() {
      if (this.selectAllTitle) return "mdi-checkbox-marked";
      return "mdi-checkbox-blank-outline";
    },
    Depticon() {
      if (this.selectAllDept) return "mdi-checkbox-marked";
      return "mdi-checkbox-blank-outline";
    },
    Nameicon() {
      if (this.selectAllName) return "mdi-checkbox-marked";
      return "mdi-checkbox-blank-outline";
    },
  },
  mounted() {
    this.$store.commit("changeTab", "tab-1");
    this.GetCategoryItems();
    this.GetArticleTitle();
    this.GetUserItems();
    this.getDepartment();
  },
  methods: {
    Searchstatus() {
      let searchdata = [];
      let oldData = this.DetailsList;

      if (this.statusdata.length == 0) {
        alert("Please select the status to search!");
      } else {
        this.statusdata.forEach((element) => {
          let testdata = oldData.filter((x) => x.status == element);
          searchdata.push(...testdata);
        });
        this.DetailsList = searchdata;
      }
    },
    clearSearchWord(val) {
      switch (val) {
        case "category":
          this.searchcategory = null;
          break;
        case "title":
          this.searchtitle = null;
          break;
        case "group":
          this.searchgroup = null;
          break;
        case "surname":
          this.searchname = null;
          break;
        default:
          console.log("clear");
      }
      this.searchInLists();
    },
    searchInLists() {
      switch (this.searchtype) {
        case "category":
          if (!this.searchcategory) {
            this.Categoryitems = this.categoryCopy;
          }

          this.Categoryitems =
            this.searchcategory != null
              ? this.categoryCopy.filter((x) => {
                  return (
                    x.topicName
                      .toLowerCase()
                      .indexOf(this.searchcategory.toLowerCase()) > -1
                  );
                })
              : this.categoryCopy;
          break;
        case "title":
          if (!this.searchtitle) {
            this.Titleitems = this.titleCopy;
          }

          this.Titleitems =
            this.searchtitle != null
              ? this.titleCopy.filter((x) => {
                  return (
                    x.title
                      .toLowerCase()
                      .indexOf(this.searchtitle.toLowerCase()) > -1
                  );
                })
              : this.titleCopy;
          break;
        case "group":
          if (!this.searchgroup) {
            this.Departmentitems = this.groupCopy;
          }

          this.Departmentitems =
            this.searchgroup != null
              ? this.groupCopy.filter((x) => {
                  return (
                    x.groupName
                      .toLowerCase()
                      .indexOf(this.searchgroup.toLowerCase()) > -1
                  );
                })
              : this.groupCopy;
          break;
        case "surname":
          if (!this.searchname) {
            this.Surnameitems = this.surnameCopy;
          }

          this.Surnameitems =
            this.searchname != null
              ? this.surnameCopy.filter((x) => {
                  return (
                    x.fullName
                      .toLowerCase()
                      .indexOf(this.searchname.toLowerCase()) > -1
                  );
                })
              : this.surnameCopy;
          break;
        default:
          console.log("Searched...");
      }
    },

    TabChange() {
      this.ArticleList = [];
      this.item_No = 0;
    },
    Search() {
      if (
        this.values.length == 0 &&
        this.course.length == 0 &&
        this.dept.length == 0 &&
        this.names.length == 0
      )
        alert("Please select condition to search!");
      else {
        if (this.$store.getters.tabs == "tab-4") {
          this.GetLLReport(2);
        } else if (this.$store.getters.tabs == "tab-3") {
          this.GetVideoReport(2);
        } else if (this.$store.getters.tabs == "tab-2") {
          this.GetSoundReport(2);
        } else this.GetReportItems(2);
      }
    },
    Details(item) {
      this.detaildialog = true;
      this.detail.title = item.titleName;
      if (this.$store.getters.tabs == "tab-1") {
        this.GetArticleRequestDetail(item);
        this.detail.category = item.topicName;
      } else this.GetLessonLearnRequestDetail(item);
    },
    MediaDetails(item) {
      this.mediaDialog = true;
      this.detail.title = item.titleName;
      this.detail.file = item.fileName;
      this.detail.category = item.topicName;
      this.detail.time = item.time;
      if (this.$store.getters.tabs == "tab-2") {
        this.GetSoundRequestDetail(item);
      } else {
        this.GetVideoRequestDetail(item);
      }
    },
    getSelectAll() {
      if (this.course.length == 0) {
        this.course = this.Categoryitems.map(({ id }) => id);
      } else {
        this.course = [];
      }
    },
    SelectCategory(event) {
      this.Titleitems = [];
      this.values = [];
      if (event.length > 0) {
        for (let i of event) {
          const found = this.tempTitle.filter((c) => c.topicID == i);
          if (found.length != 0) {
            if (this.Titleitems.length == 0) {
              this.Titleitems = found;
            } else {
              for (let title of found) {
                this.Titleitems.push(title);
              }
            }
          }
        }
      } else {
        this.Titleitems = this.tempTitle;
        this.ArticleList = [];
        this.item_No = 0;
      }
    },
    SelectTitle(event) {
      if (event.length == 0) {
        if (
          this.course.length == 0 &&
          this.dept.length == 0 &&
          this.names.length == 0
        ) {
          this.ArticleList = [];
          this.item_No = 0;
        } else {
          if (this.$store.getters.tabs == "tab-4") {
            this.GetLLReport(2);
          } else if (this.$store.getters.tabs == "tab-3") {
            this.GetVideoReport(2);
          } else if (this.$store.getters.tabs == "tab-2") {
            this.GetSoundReport(2);
          } else this.GetReportItems(2);
        }
      }
    },
    SelectDept(event) {
      this.Surnameitems = [];
      this.name = [];
      if (event.length == 0) {
        this.Surnameitems = this.tempName;
        if (
          this.values.length == 0 &&
          this.course.length == 0 &&
          this.names.length == 0
        ) {
          this.ArticleList = [];
          this.item_No = 0;
        } else {
          if (this.$store.getters.tabs == "tab-4") {
            this.GetLLReport(2);
          } else if (this.$store.getters.tabs == "tab-3") {
            this.GetVideoReport(2);
          } else if (this.$store.getters.tabs == "tab-2") {
            this.GetSoundReport(2);
          } else this.GetReportItems(2);
        }
      } else {
        let tempGp = [];
        for (let i of event) {
          let found = this.Departmentitems.find((c) => c.groupID == i);
          tempGp = found.groupEmployee.map(({ employeeID }) => employeeID);
        }
        for (let item of tempGp) {
          let found1 = this.tempName.filter((c) => c.userID == item);
          if (this.Surnameitems.length == 0) this.Surnameitems = found1;
          else {
            for (let name of found1) {
              this.Surnameitems.push(name);
            }
          }
        }
      }
    },
    SelectName(event) {
      if (event.length == 0) {
        if (
          this.values.length == 0 &&
          this.dept.length == 0 &&
          this.course.length == 0
        ) {
          this.ArticleList = [];
          this.item_No = 0;
        } else {
          if (this.$store.getters.tabs == "tab-4") {
            this.GetLLReport(2);
          } else if (this.$store.getters.tabs == "tab-3") {
            this.GetVideoReport(2);
          } else if (this.$store.getters.tabs == "tab-2") {
            this.GetSoundReport(2);
          } else this.GetReportItems(2);
        }
      }
    },
    async GetCategoryItems() {
      let that = this;
      axios
        .get(
          `${that.web_url}Topic/GetTopicDDL?id=` +
            localStorage.getItem("companyID")
        )
        .then(function (response) {
          if (response.data.status == 0) {
            let temp = response.data.data.map((v) => ({
              ...v,
            }));
            that.Categoryitems = temp.sort((a, b) =>
              a.topicName.toLowerCase() > b.topicName.toLowerCase() ? 1 : -1
            );
            that.categoryCopy = that.Categoryitems;
          }
        })
        .catch(function (err) {
          throw err;
        });
      return that.Categoryitems.map((item1, index) => ({
        ...item1,
        no: index + 1,
      }));
    },
    async GetSoundTitle() {
      let that = this;
      that.search = null;
      that.tempTitle = [];
      axios
        .get(
          `${that.web_url}Video/GetSoundDDL?id=` +
            localStorage.getItem("companyID")
        )
        .then(function (response1) {
          if (response1.data.status == 0) {
            let temp = response1.data.data.map((v) => ({
              ...v,
            }));
            that.Titleitems = temp.sort((a, b) =>
              a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1
            );
            that.tempTitle = that.Titleitems;
            that.titleCopy = that.Titleitems;
          }
        })
        .catch(function (err) {
          throw err;
        });
    },
    async GetVideoTitle() {
      let that = this;
      that.search = null;
      that.Titleitems = [];
      axios
        .get(
          `${that.web_url}GuidedLesson/GetVideoDDL?id=` +
            localStorage.getItem("companyID")
        )
        .then(function (response2) {
          if (response2.data.status == 0) {
            let temp = response2.data.data.map((v) => ({
              ...v,
            }));
            that.Titleitems = temp.sort((a, b) =>
              a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1
            );
            that.tempTitle = that.Titleitems;
            that.titleCopy = that.Titleitems;
          }
        })
        .catch(function (err) {
          throw err;
        });
    },
    async GetArticleTitle() {
      let that = this;
      that.search = null;
      await axios
        .get(
          `${that.web_url}Contents/GetArticleDDL?id=` +
            localStorage.getItem("companyID")
        )
        .then(function (response) {
          if (response.data.status == 0) {
            let temp = response.data.data.map((v) => ({
              ...v,
            }));
            that.Titleitems = temp.sort((a, b) =>
              a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1
            );
            that.tempTitle = that.Titleitems;
            that.titleCopy = that.Titleitems;
          }
        })
        .catch(function (err) {
          throw err;
        });
    },
    async GetLessonLearnTitle() {
      let that = this;
      that.search = null;
      axios
        .get(
          `${that.web_url}CaseStudies/GetLessonLearnDDL?id=` +
            localStorage.getItem("companyID")
        )
        .then(function (response) {
          if (response.data.status == 0) {
            let temp = response.data.data.map((v) => ({
              ...v,
            }));
            that.Titleitems = temp.sort((a, b) =>
              a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1
            );
            that.tempTitle = that.Titleitems;
            that.titleCopy = that.Titleitems;
          }
        })
        .catch(function (err) {
          throw err;
        });
    },
    SelectAllTitle() {
      if (this.values.length == 0) {
        this.values = this.Titleitems.map(({ id }) => id);
      } else {
        this.values = [];
      }
    },
    GetUserItems() {
      let that = this;
      axios
        .get(
          `${that.web_url}EmployeeEXT/GetEmployeeExtAll?Id=` +
            localStorage.getItem("companyID")
        )
        .then(function (response) {
          if (response.data.status == 0) {
            let temp = response.data.data.map((v) => ({
              ...v,
            }));
            that.Surnameitems = temp.sort((a, b) =>
              a.firstName.toLowerCase() > b.firstName.toLowerCase() ? 1 : -1
            );
            that.tempName = that.Surnameitems;
            that.surnameCopy = that.Surnameitems;
          }
        })
        .catch(function (err) {
          throw err;
        });
      return that.Surnameitems.map((item, index) => ({
        ...item,
        no: index + 1,
      }));
    },
    getAllDept() {
      if (this.dept.length == 0) {
        this.dept = this.Departmentitems.map(({ groupID }) => groupID);
      } else {
        this.dept = [];
      }
    },
    getAllName() {
      if (this.names.length == 0) {
        this.names = this.Surnameitems.map(({ userID }) => userID);
      } else {
        this.names = [];
      }
    },
    async getDepartment() {
      let self = this;
      const res = await self.$axios.get(
        `${self.web_url}Group/GetGroupALL?id=` +
          localStorage.getItem("companyID")
      );
      if (res.data.status == 0) {
        let temp = res.data.data;
        self.Departmentitems = temp.sort((a, b) =>
          a.groupName.toLowerCase() > b.groupName.toLowerCase() ? 1 : -1
        );
        self.groupCopy = self.Departmentitems;
      }
    },
    async GetReportItems(page) {
      let that = this;
      that.ArticleList = [];
      that.loading = true;
      let request = {
        companyID: localStorage.getItem("companyID"),
        topicID: page == 1 ? [] : this.course,
        titleID: page == 1 ? [] : this.values,
        departmentID: page == 1 ? [] : this.dept,
        userID: page == 1 ? [] : this.names,
        separatePage: page == 1 ? 1 : 2,
      };
      await axios
        .post(`${that.web_url}Reports/GetArticleRequestReport`, request)
        .then(function (response) {
          if (response.data.status == 0) {
            that.ArticleList = response.data.data.map((v) => ({
              ...v,
            }));
            that.item_No = that.ArticleList.length;
          }
        })
        .catch(function (err) {
          throw err;
        });
      that.loading = false;
      return that.ArticleList.map((item, index) => ({
        ...item,
        no: index + 1,
      }));
    },
    async GetLLReport(page) {
      let that = this;
      that.ArticleList = [];
      that.loading = true;
      let request = {
        companyID: localStorage.getItem("companyID"),
        titleID: page == 1 ? [] : this.values,
        departmentID: page == 1 ? [] : this.dept,
        userID: page == 1 ? [] : this.names,
        separatePage: page == 1 ? 1 : 2,
      };
      await axios
        .post(`${that.web_url}Reports/GetLessonLearnRequestReport`, request)
        .then(function (response) {
          if (response.data.status == 0) {
            that.ArticleList = response.data.data.map((v) => ({
              ...v,
            }));
            that.item_No = that.ArticleList.length;
          }
        })
        .catch(function (err) {
          throw err;
        });
      that.loading = false;
      return that.ArticleList.map((item, index) => ({
        ...item,
        no: index + 1,
      }));
    },
    async GetSoundReport(page) {
      let that = this;
      that.ArticleList = [];
      that.loading = true;
      let request = {
        companyID: localStorage.getItem("companyID"),
        topicID: page == 1 ? [] : this.course,
        titleID: page == 1 ? [] : this.values,
        departmentID: page == 1 ? [] : this.dept,
        userID: page == 1 ? [] : this.names,
        separatePage: page == 1 ? 1 : 2,
      };
      await axios
        .post(`${that.web_url}Reports/GetSoundRequestReport`, request)
        .then(function (response) {
          if (response.data.status == 0) {
            that.ArticleList = response.data.data.map((v) => ({
              ...v,
            }));
            that.item_No = that.ArticleList.length;
          }
        })
        .catch(function (err) {
          throw err;
        });
      that.loading = false;
      return that.ArticleList.map((item, index) => ({
        ...item,
        no: index + 1,
      }));
    },
    async GetVideoReport(page) {
      let that = this;
      that.ArticleList = [];
      that.loading = true;
      let request = {
        companyID: localStorage.getItem("companyID"),
        topicID: page == 1 ? [] : this.course,
        titleID: page == 1 ? [] : this.values,
        departmentID: page == 1 ? [] : this.dept,
        userID: page == 1 ? [] : this.names,
        separatePage: page == 1 ? 1 : 2,
      };
      await axios
        .post(`${that.web_url}Reports/GetVideoRequestReport`, request)
        .then(function (response) {
          if (response.data.status == 0) {
            that.ArticleList = response.data.data.map((v) => ({
              ...v,
            }));
            that.item_No = that.ArticleList.length;
          }
        })
        .catch(function (err) {
          throw err;
        });
      that.loading = false;
      return that.ArticleList.map((item1, index) => ({
        ...item1,
        no: index + 1,
      }));
    },
    async GetArticleRequestDetail(item) {
      let that = this;
      that.detailloading = true;
      that.DetailsList = [];
      that.statusdata = [];
      let request = {
        titleID: item.titleID,
        departmentID: this.dept,
        userID: this.names,
        isUserGroup: item.isUserGroup,
        offset: that.dateoffset,
      };
      await axios
        .post(`${that.web_url}Reports/GetArticleRequestDetailReport`, request)
        .then(function (response2) {
          if (response2.data.status == 0) {
            that.articleseries = response2.data.data1.map(
              (item) => item.percent
            );
            that.chartOptionsarticle.labels = response2.data.data1.map((v) =>
              String(v.status)
            );
            that.chartkey++;
            that.DetailsList = response2.data.data.map((v) => ({
              ...v,
              courseName: item.topicName,
              title: item.titleName,
            }));
          }
        })
        .catch(function (err) {
          throw err;
        });
      that.detailloading = false;
      return that.DetailsList.map((item1, index) => ({
        ...item1,
        no: index + 1,
      }));
    },
    async GetLessonLearnRequestDetail(item) {
      let that = this;
      that.detailloading = true;
      that.DetailsList = [];
      that.statusdata = [];
      let request = {
        titleID: item.titleID,
        departmentID: this.dept,
        userID: this.names,
        isUserGroup: item.isUserGroup,
        offset: that.dateoffset,
      };
      await axios
        .post(
          `${that.web_url}Reports/GetLessonLearnRequestDetailReport`,
          request
        )
        .then(function (response1) {
          if (response1.data.status == 0) {
            that.articleseries = response1.data.data1.map(
              (item) => item.percent
            );
            that.chartOptionsarticle.labels = response1.data.data1.map((v) =>
              String(v.status)
            );
            that.chartkey++;
            that.DetailsList = response1.data.data.map((v) => ({
              ...v,
              title: item.titleName,
            }));
          }
        })
        .catch(function (err) {
          throw err;
        });
      that.detailloading = false;
      return that.DetailsList.map((item1, index) => ({
        ...item1,
        no: index + 1,
      }));
    },
    async GetSoundRequestDetail(item) {
      let that = this;
      that.detailloading = true;
      that.DetailsList = [];
      that.statusdata = [];
      let request = {
        videoID: item.soundID,
        departmentID: this.dept,
        userID: this.names,
        isUserGroup: item.isUserGroup,
        offset: that.dateoffset,
        time: item.time,
      };
      await axios
        .post(`${that.web_url}Reports/GetSoundRequestDetailReport`, request)
        .then(function (response) {
          if (response.data.status == 0) {
            that.videoseries = response.data.data1.map((item) => item.percent);
            that.chartOptionscourse.labels = response.data.data1.map((v) =>
              String(v.status)
            );
            that.chartkey++;
            that.DetailsList = response.data.data.map((v) => ({
              ...v,
              courseName: item.topicName,
              title: item.titleName,
              file: item.fileName,
              time: that.detail.viewTime,
            }));
          }
        })
        .catch(function (err) {
          throw err;
        });
      that.detailloading = false;
      return that.DetailsList.map((item1, index) => ({
        ...item1,
        no: index + 1,
      }));
    },
    async GetVideoRequestDetail(item) {
      let that = this;
      that.detailloading = true;
      that.DetailsList = [];
      that.statusdata = [];
      let request = {
        videoID: item.videoID,
        departmentID: this.dept,
        userID: this.names,
        isUserGroup: item.isUserGroup,
        offset: that.dateoffset,
        time: item.time,
      };
      await axios
        .post(`${that.web_url}Reports/GetVideoRequestDetailReport`, request)
        .then(function (response3) {
          if (response3.data.status == 0) {
            that.videoseries = response3.data.data1.map((item) => item.percent);
            that.chartOptionscourse.labels = response3.data.data1.map((v) =>
              String(v.status)
            );
            that.chartkey++;
            that.DetailsList = response3.data.data.map((v) => ({
              ...v,
              courseName: item.topicName,
              title: item.titleName,
              file: item.fileName,
              time: that.detail.viewTime,
            }));
          }
        })
        .catch(function (err) {
          throw err;
        });
      that.detailloading = false;
      return that.DetailsList.map((item1, index) => ({
        ...item1,
        no: index + 1,
      }));
    },
    async exportExcel() {
      let headers = [];
      let keys = [];
      let name = "";
      let fileName = "";
      if (this.$store.getters.tabs == "tab-1") {
        name = "Article";
        fileName = `${"RequestedUserReport - Article"}.xlsx`;
        headers = [
          "Category",
          "Title",
          "Requested User",
          "Finished view user",
          "Finished before expiration",
          "Finished after expiration",
          "Not finished",
        ];
        keys = [
          "topicName",
          "titleName",
          "requestedUser",
          "finishedUser",
          "finishBeforeExpiration",
          "finishedAfterExpiration",
          "notFinishedUser",
        ];
      } else if (
        this.$store.getters.tabs == "tab-2" ||
        this.$store.getters.tabs == "tab-3"
      ) {
        if (this.$store.getters.tabs == "tab-2") {
          name = "Sound";
          fileName = `${"RequestedUserReport - Sound"}.xlsx`;
        } else {
          name = "Video";
          fileName = `${"RequestedUserReport - Video"}.xlsx`;
        }
        headers = [
          "Category",
          "Title",
          "File name",
          "Requested User",
          "Finished view user",
          "Finished before expiration",
          "Finished after expiration",
          "Not finished",
          "Not started",
        ];
        keys = [
          "topicName",
          "titleName",
          "fileName",
          "requestedUser",
          "finishedUser",
          "finishBeforeExpiration",
          "finishedAfterExpiration",
          "notFinishedUser",
          "notStartedUser",
        ];
      } else {
        name = "Lesson Learn";
        fileName = `${"RequestedUserReport - Lesson Learn"}.xlsx`;

        headers = [
          "Title",
          "Requested User",
          "Finished view user",
          "Finished before expiration",
          "Finished after expiration",
          "Not finished",
        ];
        keys = [
          "titleName",
          "requestedUser",
          "finishedUser",
          "finishBeforeExpiration",
          "finishedAfterExpiration",
          "notFinishedUser",
        ];
      }

      const filterItems = this.ArticleList.map((x) => ({ ...x }));
      const data = [];
      data.push(headers);
      for (const item of filterItems) {
        let row = [];

        for (const key of keys) {
          row.push(item[key] || 0);
        }
        data.push(row);
      }
      let workbook = new exceljs.Workbook();

      workbook.addWorksheet(name, {
        views: [{ showGridLines: true }],
      });
      let worksheet = workbook.getWorksheet(1);

      data.forEach((element) => {
        worksheet.addRow(element);
      });
      worksheet.getRow(1).eachCell((cell, number) => {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "ff9900" },
          bgColor: { argb: "ff9900" },
        };
        cell.font = {
          name: "Calibri",
          size: 11,
          color: { argb: "000000" },
          bold: true,
        };
        console.log(number);
        cell.alignment = { vertical: "middle", horizontal: "center" };
      });
      worksheet.getRows(1, worksheet.actualRowCount - 1).forEach((x) =>
        x.eachCell((cell) => {
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
          cell.alignment = { vertical: "middle", horizontal: "center" };
        })
      );
      worksheet.lastRow.eachCell((cell) => {
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
        cell.alignment = { vertical: "middle", horizontal: "center" };
      });
      worksheet.columns.forEach((column, i) => {
        column.width = headers[i].length + (i <= 10 ? 15 : 19);
      });
      workbook.xlsx.writeBuffer().then((val) => {
        let blob = new Blob([val], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
        });
        saveAs(blob, fileName);
      });
    },
    async exportDetailExcel() {
      let headers = [];
      let keys = [];
      let name = "";
      if (
        this.$store.getters.tabs == "tab-1" ||
        this.$store.getters.tabs == "tab-4"
      ) {
        name =
          this.$store.getters.tabs == "tab-1"
            ? "Article-Detail"
            : "LessonLearn-Detail";
        headers = [
          "Category",
          "Title",
          "Name - Surname",
          "Department",
          "Expired date",
          "First view date",
          "Status",
        ];
        keys = [
          "courseName",
          "title",
          "fullName",
          "departmentName",
          "expiredDate",
          "firstViewDate",
          "status",
        ];
      } else {
        name =
          this.$store.getters.tabs == "tab-2" ? "Sound-Detail" : "Video-Detail";
        headers = [
          "Category",
          "Title",
          "File Name",
          "Name - Surname",
          "Department",
          "Expired date",
          "First view date",
          "Last view date",
          "View Time",
          "Status",
        ];
        keys = [
          "courseName",
          "title",
          "file",
          "fullName",
          "departmentName",
          "expiredDate",
          "firstViewDate",
          "lastViewDate",
          "viewTime",
          "status",
        ];
      }
      const fileName = `${"RequestedUserReport"}.xlsx`;
      const filterItems = this.DetailsList.map((x) => ({ ...x }));
      const data = [];
      data.push(headers);
      for (const item of filterItems) {
        let row = [];

        for (const key of keys) {
          row.push(item[key] || 0);
        }
        data.push(row);
      }
      let workbook2 = new exceljs.Workbook();

      workbook2.addWorksheet(name, {
        views: [{ showGridLines: true }],
      });
      let worksheet2 = workbook2.getWorksheet(1);

      data.forEach((element) => {
        worksheet2.addRow(element);
      });
      worksheet2.getRow(1).eachCell((cell, number) => {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "ff9900" },
          bgColor: { argb: "ff9900" },
        };
        cell.font = {
          name: "Calibri",
          size: 11,
          color: { argb: "000000" },
          bold: true,
        };
        console.log(number);
        cell.alignment = { vertical: "middle", horizontal: "center" };
      });
      worksheet2.getRows(1, worksheet2.actualRowCount - 1).forEach((x) =>
        x.eachCell((cell) => {
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
          cell.alignment = { vertical: "middle", horizontal: "center" };
        })
      );
      worksheet2.lastRow.eachCell((cell) => {
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
        cell.alignment = { vertical: "middle", horizontal: "center" };
      });
      worksheet2.columns.forEach((column, i) => {
        column.width = headers[i].length + (i <= 8 ? 10 : 13);
      });
      workbook2.xlsx.writeBuffer().then((val) => {
        let blob = new Blob([val], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
        });
        saveAs(blob, fileName);
      });
    },
  },
};
</script>
<style scoped>
::v-deep .pre-fix .v-input__slot {
  border: 1px solid #707070;
  font-size: 16px !important;
  border-radius: 4px !important;
  align-content: end;
}
::v-deep .v-input__control {
  align-content: end;
}
::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  font-size: 17px !important;
  color: #47484b !important;
  background-color: #e0e0e0 !important;
  opacity: 1;
}
::v-deep .primary {
  background-color: #a6cc39 !important;
}
::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td {
  font-size: 16px !important;
  color: #47484b !important;
  opacity: 1;
  vertical-align: center;
}
::v-deep .pagination .primary {
  background-color: #a6cc39 !important;
  color: #ffffff !important;
  opacity: 1;
}
::v-deep .v-dialog {
  border-radius: 30px !important;
}
::v-deep .btnStyle .v-btn__content {
  font-size: 20px;
  color: #fff;
}
::v-deep .primary--text {
  color: #a6cc39 !important;
}
::v-deep .accent {
  background-color: #a6cc39 !important;
}
::v-deep .accent--text {
  color: #a6cc39 !important;
}
::v-deep .v-list-item__title {
  color: #4d4f5c !important;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .tab-mobileres {
    width: 300px;
    overflow: auto;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  .tab-mobileres {
    width: 500px;
    overflow: auto;
  }
}
.searchinlist.v-text-field {
  margin-left: 5px;
  padding-top: 0px;
  margin-top: 0px;
}
</style>

<style>
.v-card {
  padding: 0px !important;
}
.view-table thead th:first-child {
  border-radius: 8px 0 0 8px !important;
}
.view-table thead th:last-child {
  border-radius: 0px 8px 8px 0px !important;
}
.v-pagination__navigation {
  border-radius: 6px !important;
  box-shadow: none !important;
  border: 1px solid #c0c0c0;
}
.v-pagination__item {
  background: transparent !important;
  box-shadow: none !important;
}
.dialog-p {
  margin-bottom: 0px !important;
}

.finished {
  color: #4fb14e !important;
  background: rgba(111, 220, 66, 0.1) !important;
}
.notstarted {
  color: #ff6060 !important;
  background: rgba(255, 96, 96, 0.1) !important;
}
.notfinished {
  color: #424242 !important;
  background: rgba(66, 66, 66, 0.1) !important;
}
.before {
  color: #4390df !important;
  background: rgba(67, 144, 223, 0.1) !important;
}
.afterexpired {
  color: #ffb100 !important;
  background: rgba(255, 177, 0, 0.1) !important;
}
</style>
